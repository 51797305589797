// export const baseUrl = 'https://apiprinton.aata.in'
// export const baseUrl = 'http://192.168.0.124:7011'
// export const baseUrl = "https://api.printon.co.in";
export const domainUrl = "https://printon.co.in";
export const baseUrl = 'https://devapi.printon.co.in';

const productCategory = `${baseUrl}/auth/productCategory`;
const productCategoryAdmin = `${baseUrl}/productCategory`;
const product = `${baseUrl}/auth/product`;
const fieldTypeApiNew = `${baseUrl}/auth/fieldType`;
const order = `${baseUrl}/auth/order`;
const fieldOption = `${baseUrl}/auth/fieldOptions`;
const fileUpload = `${baseUrl}/auth/upload`;
const otpSend = `${baseUrl}/otp/send`;
const otpVerify = `${baseUrl}/otp/verify`;
const signIn = `${baseUrl}/signin`;
const signUp = `${baseUrl}/signup`;
const addCart = `${baseUrl}/addCart`;
const cart = `${baseUrl}/cart`;
const orderPost = `${baseUrl}/order`;
const placeOrder = `${baseUrl}/placeOrder`;
const cartCount = `${baseUrl}/cart-count-update`;
const productCategoryApi = `${baseUrl}/productCategory`;
const productCategoryStatus = `${baseUrl}/category-status`;
const fieldOptionApi = `${baseUrl}/fieldOptions`;
const fieldTypeApi = `${baseUrl}/fieldType`;
const productApi = `${baseUrl}/product`;
const removeCart = `${baseUrl}/remove-cart`;
const orderStats = `${baseUrl}/order-stats`;
const productType = `${baseUrl}/productType`;
const productRateUpdate = `${baseUrl}/productRateUpdate`;
const quantityCountApi = `${baseUrl}/auth/product-dropdown`;
const speedSale = `${baseUrl}/auth/product?speedSale=yes`;
const productFieldType = `${baseUrl}/productFieldType`;
const getCartRate = `${baseUrl}/auth/getCartRate`;
const getOfferProduct = `${baseUrl}/offer`;
const wishList = `${baseUrl}/wishList`;
const socialMedia = `${baseUrl}/socialMedia`;
const questions = `${baseUrl}/question`;
const askedQueAns = `${baseUrl}/askedQueAns`;
const answer = `${baseUrl}/answer`;
const payId = `${baseUrl}/pay`;
const rateandreview = `${baseUrl}/ratingReview`;
const mobileCheck = `${baseUrl}/auth/mobileCheck`;
const emailChecking = `${baseUrl}/auth/emailCheck`;
const productSearch = `${baseUrl}/auth/productSearch`;
const productTypeSearch = `${baseUrl}/auth/productType`;
const multiUpload = `${baseUrl}/auth/mulit_upload`;
const orderStatus = `${baseUrl}/order-status`;
const profile = `${baseUrl}/profile`;
const profileEdit = `${baseUrl}/profile-update`;
const getFieldTypeOptions = `${baseUrl}/auth/getFieldType`;
const addQuantityBasedAmount = `${baseUrl}/addQuantity`;
const editQuantityBasedAmount = `${baseUrl}/quantityBasedAmount`;
const productTable = `${baseUrl}/productTable`;
const customerProducts = `${baseUrl}/customerProducts`;
const menuProduct = `${baseUrl}/auth/menuCombo`;
const menuProductAd = `${baseUrl}/menuCombo`;
const productDropdown = `${baseUrl}/auth/dropdown-product`;
const categoryDropdown = `${baseUrl}/auth/category-dropdown`;
const news = `${baseUrl}/newsTiker`;
const newsTikerStatus = `${baseUrl}/newsTikerStatus`;
const cusNewsTiker = `${baseUrl}/auth/newsTiker`;
const productFAQ = `${baseUrl}/productFAQ`;
const productDescription = `${baseUrl}/productDescription`;
const categoryFAQ = `${baseUrl}/categoryFAQ`;
const menusStatus = `${baseUrl}/menuComboStatus`;
const fieldType = `${baseUrl}/fieldType-table`;
const categoryGet = `${baseUrl}/category-table`;
const offerPro = `${baseUrl}/offer-table`;
const productStatus = `${baseUrl}/product-status`;

export {
  productCategory,
  product,
  fieldTypeApiNew,
  fieldOption,
  order,
  fileUpload,
  addCart,
  otpSend,
  otpVerify,
  cart,
  orderPost,
  placeOrder,
  cartCount,
  productCategoryApi,
  productCategoryStatus,
  fieldOptionApi,
  fieldTypeApi,
  productApi,
  removeCart,
  orderStats,
  productType,
  productRateUpdate,
  signIn,
  speedSale,
  productFieldType,
  quantityCountApi,
  signUp,
  getCartRate,
  getOfferProduct,
  wishList,
  socialMedia,
  payId,
  questions,
  askedQueAns,
  answer,
  rateandreview,
  mobileCheck,
  emailChecking,
  productSearch,
  productTypeSearch,
  multiUpload,
  orderStatus,
  profile,
  profileEdit,
  productCategoryAdmin,
  getFieldTypeOptions,
  addQuantityBasedAmount,
  editQuantityBasedAmount,
  productTable,
  customerProducts,
  menuProduct,
  productDropdown,
  categoryDropdown,
  news,
  newsTikerStatus,
  cusNewsTiker,
  productFAQ,
  categoryFAQ,
  menusStatus,
  menuProductAd,
  fieldType,
  categoryGet,
  offerPro,
  productStatus,
  productDescription,
};
